<template>
  <Layout>
    <PageHeader :items="items" :title="title" />
    <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" dismissible variant="success">
        Meal Plan Added Successfully!
      </b-alert>
    </div>

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <label class="form-label" for="formrow-endTime-input"
                >Select Corporate Branch *</label
              >
              <multiselect
                v-model="corpBranchID"
                :class="{
                  'is-invalid': submitted && $v.corpBranchID.$error
                }"
                :disabled="isEdit"
                :options="corporates"
                label="corpBranchName"
                track-by="corpBranchID"
                @input="changeCorporate"
              ></multiselect>
              <div
                v-if="submitted && $v.corpBranchID.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.corpBranchID.required"
                  >This value is required.</span
                >
              </div>
            </div>

            <div class="col-md-6">
              <label class="form-label" for="formrow-endTime-input"
                >Select Restaurant Branch*</label
              >
              <multiselect
                v-model="restBranch"
                :class="{
                  'is-invalid': submitted && $v.restBranch.$error
                }"
                :disabled="isEdit"
                :options="cafeteriaArr"
                label="restaurantName"
                track-by="restBranchID"
              ></multiselect>
              <div
                v-if="submitted && $v.restBranch.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.restBranch.required"
                  >This value is required.</span
                >
              </div>
            </div>
          </div>

          <div v-if="isEditMin == false" class="row mt-3">
            <div class="col-md-6">
              <label class="form-label" for="formrow-endTime-input"
                >Select Corporate Holiday</label
              >
              <multiselect
                v-model="holidayID"
                :disabled="isEditMin"
                :options="holidayList"
                label="holidayTitle"
                multiple
                track-by="holidayID"
              ></multiselect>
            </div>

            <div class="col-md-2 mt-4">
              <input
                id="excludeDays"
                v-model="excludeSat"
                class="form-check-input"
                type="checkbox"
              />
              <label
                class="form-check-label"
                for="excludeDays"
                style="margin-left: 5px;"
              >
                Exclude Saturday
              </label>
            </div>

            <div class="col-md-3 mt-4">
              <input
                id="excludSun"
                v-model="excludeSun"
                class="form-check-input"
                type="checkbox"
              />
              <label
                class="form-check-label"
                for="excludSun"
                style="margin-left: 5px;"
              >
                Exclude Sunday
              </label>
            </div>
          </div>

          <div v-if="isEditMin == false" class="row mt-3">
            <label> Applicable To</label>
            <div class="col-md-2">
              <b-form-radio
                v-model="applicableTo"
                class="custom-radio mb-3"
                plain
                value="1"
                >All Employees</b-form-radio
              >
            </div>
            <div class="col-md-2">
              <b-form-radio v-model="applicableTo" class="mb-3" plain value="2"
                >Selected Department</b-form-radio
              >
            </div>
            <div class="col-md-2">
              <b-form-radio v-model="applicableTo" class="mb-3" plain value="3"
                >Selected Employees</b-form-radio
              >
            </div>
          </div>
          <div v-if="applicableTo == 2 && isEditMin == false" class="row mt-3">
            <div class="col-md-3">
              <label class="form-label" for="formrow-firstname-input"
                >Select Department*</label
              ><br />
              <button
                class="btn btn-themeOrange  w-md waves-effect waves-light "
                type="button"
                @click="openDepartmentModal()"
              >
                Departments
              </button>
            </div>

            <p
              :class="{
                'is-invalid': submitted && $v.selectedDepartment.$error
              }"
              class="col-md-6"
            >
              Selected Department:<br />
              <!-- {{selectedDepartment}} -->
              {{ selectedDepartment.length }}
            </p>
            <div
              v-if="submitted && $v.selectedDepartment.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.selectedDepartment.required"
                >This value is required.</span
              >
            </div>
          </div>

          <div v-if="applicableTo == 3 && isEditMin == false" class="row mt-3">
            <div class="col-md-3">
              <label class="form-label" for="formrow-firstname-input"
                >Select Employees*</label
              ><br />
              <button
                class="btn btn-themeOrange  w-md waves-effect waves-light "
                type="button"
                @click="openEmployeeModal()"
              >
                Employees
              </button>
            </div>

            <p
              :class="{
                'is-invalid': submitted && $v.selectedEmployees.$error
              }"
              class="col-md-6"
            >
              Selected Employees:<br />
              <!-- {{selectedDepartment}} -->
              {{ selectedEmployees.length }}
            </p>
            <div
              v-if="submitted && $v.selectedEmployees.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.selectedEmployees.required"
                >This value is required.</span
              >
            </div>
          </div>
          <div v-if="isEditMin == false" class="row mt-3">
            <div class="col-md-4">
              <div class="row">
                <label>Show Selling Price</label>
                <div class="col-md-6">
                  <b-form-radio
                    v-model="showSellingPrice"
                    class="custom-radio mb-3"
                    plain
                    value="1"
                    >Yes</b-form-radio
                  >
                </div>
                <div class="col-md-6">
                  <b-form-radio
                    v-model="showSellingPrice"
                    class="mb-3"
                    plain
                    value="2"
                    >No</b-form-radio
                  >
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <label>Enable Pre-Order</label>
                <div class="col-md-6">
                  <b-form-radio
                    v-model="enablePreOrder"
                    class="custom-radio mb-3"
                    plain
                    value="1"
                    >Yes</b-form-radio
                  >
                </div>
                <div class="col-md-6">
                  <b-form-radio
                    v-model="enablePreOrder"
                    class="mb-3"
                    plain
                    value="0"
                    >No</b-form-radio
                  >
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <label>Show Weekly Menu</label>
                <div class="col-md-6">
                  <b-form-radio
                    v-model="weeklyMenuVisibility"
                    class="custom-radio mb-3"
                    plain
                    value="1"
                    >Yes</b-form-radio
                  >
                </div>
                <div class="col-md-6">
                  <b-form-radio
                    v-model="weeklyMenuVisibility"
                    class="mb-3"
                    plain
                    value="0"
                    >No</b-form-radio
                  >
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-12">
                  <label>Bulk Order Access</label>
                  <multiselect
                    v-model="bulkOrderAdmin"
                    :options="corporateAdminsList"
                    :show-labels="false"
                    label="corpAdminName"
                    track-by="corpAdminID"
                  ></multiselect>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <label>Pre-Order Cut-Off Time</label>
                <div class="col-md-6">
                  <b-time v-model="preOrderCutOff" locale="en"></b-time>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <label>Cancellation Cut-Off Time</label>
                <div class="col-md-6">
                  <b-time v-model="cancellationCutOff" locale="en"></b-time>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isEditMin == false" class="row mt-3">
            <div class="col-md-4 mb-3">
              <label>Start Date</label>
              <br />
              <input
                id="formrow-startDate-input"
                v-model="startDate"
                :min="nowDate"
                class="form-control"
                type="date"
              />
            </div>

            <div class="col-md-4 mb-3">
              <label>End Date</label>
              <br />
              <input
                id="formrow-endDate-input"
                v-model="endDate"
                :min="nowDate"
                class="form-control"
                type="date"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h6>Create Meals</h6>

          <div class="inner-repeater mb-4">
            <div class="inner mb-3">
              <div
                v-for="(data, index) in mealsArr"
                :key="data.id"
                class="inner mb-3 row"
              >
                <div class="col-md-3">
                  <label class="form-label mt-2" style="font-size: 12px;"
                    >Meal Type : *</label
                  >
                  <input
                    v-model="data.mealTypeName"
                    :disabled="isEditMin"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div v-if="isEditMin == false" class="col-md-3">
                  <label class="form-label mt-2" style="font-size: 12px;"
                    >Discount Type: *</label
                  >
                  <multiselect
                    v-model="data.discountType"
                    :disabled="isEditMin"
                    :options="discountTypeArr"
                    :show-labels="false"
                    label="disTypeName"
                    track-by="disType"
                  ></multiselect>
                </div>
                <div
                  v-if="data.discountType.disType == 1 && isEditMin == false"
                  class="col-md-3"
                >
                  <label
                    class="form-label mt-2"
                    for="formrow-price-input"
                    style="font-size: 12px;"
                    >Discount *</label
                  >
                  <div class="input-group">
                    <div class="input-group-text">₹</div>

                    <input
                      id="formrow-price-input"
                      v-model="data.discountInRs"
                      :disabled="isEditMin"
                      class="form-control"
                      type="number"
                    />
                  </div>
                </div>
                <div
                  v-if="data.discountType.disType == 2 && isEditMin == false"
                  class="col-md-3"
                >
                  <label
                    class="form-label mt-2"
                    for="formrow-price-input"
                    style="font-size: 12px;"
                    >Discount *</label
                  >
                  <div class="input-group">
                    <input
                      id="formrow-price-input"
                      v-model="data.discountPercent"
                      :disabled="isEditMin"
                      :max="100"
                      class="form-control"
                      type="number"
                    />
                    <div class="input-group-text">%</div>
                  </div>
                </div>
                <div
                  v-if="data.discountType.disType == 2 && isEditMin == false"
                  class="col-md-3"
                >
                  <label
                    class="form-label mt-2"
                    for="formrow-price-input"
                    style="font-size: 12px;"
                    >Max Discount Capping
                  </label>
                  <div class="input-group">
                    <div class="input-group-text">₹</div>
                    <input
                      id="formrow-price-input"
                      v-model="data.maxDiscountCapping"
                      :disabled="isEditMin"
                      :min="0"
                      class="form-control"
                      type="number"
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <label class="form-label mt-2" style="font-size: 12px;"
                    >Minimum Guarantee Veg Count: *</label
                  >
                  <input
                    v-model="data.vegCount"
                    :disabled="disableMin"
                    class="form-control"
                    type="number"
                  />
                </div>

                <div class="col-md-3">
                  <label class="form-label mt-2" style="font-size: 12px;"
                    >Minimum Guarantee Non-veg Count: *</label
                  >
                  <input
                    v-model="data.nonVegCount"
                    :disabled="disableMin"
                    class="form-control"
                    type="number"
                  />
                </div>

                <div v-if="isEditMin == false" class="col-md-3">
                  <label class="form-label mt-2" style="font-size: 12px;"
                    >Minimum Guarantee Veg (in Rs): *</label
                  >
                  <div class="input-group">
                    <div class="input-group-text">₹</div>
                    <input
                      v-model="data.vegCountInRs"
                      :disabled="isEditMin"
                      class="form-control"
                      type="number"
                    />
                  </div>
                </div>

                <div v-if="isEditMin == false" class="col-md-3">
                  <label class="form-label mt-2" style="font-size: 12px;"
                    >Minimum Guarantee Non-veg (in Rs): *</label
                  >
                  <div class="input-group">
                    <div class="input-group-text">₹</div>
                    <input
                      v-model="data.nonVegCountInRs"
                      :disabled="isEditMin"
                      class="form-control"
                      type="number"
                    />
                  </div>
                </div>
                <div v-if="isEditMin == false" class="col-md-3">
                  <label class="form-label mt-2" style="font-size: 12px;"
                    >Available From:
                  </label>
                  <div class="input-group">
                    <input
                      v-model="data.start_time"
                      :disabled="isEditMin"
                      class="form-control"
                      type="time"
                    />
                  </div>
                </div>
                <div v-if="isEditMin == false" class="col-md-3">
                  <label class="form-label mt-2" style="font-size: 12px;"
                    >Available Till:
                  </label>
                  <div class="input-group">
                    <input
                      v-model="data.end_time"
                      :disabled="isEditMin"
                      class="form-control"
                      type="time"
                    />
                  </div>
                </div>
                <p v-if="isEditMin" style="color:#F3766A;">
                  *Updated count will be effective from tomorrow <br />
                  *Updation allowed till 11:00 PM
                </p>

                <div class="col-lg-2 align-self-center d-grid mt-3">
                  <input
                    v-if="isEditMin == false"
                    class="btn btn-themeBrown btn-block inner"
                    type="button"
                    value="Delete"
                    @click="deleteItem(index, data.corpMealID)"
                  />
                </div>
                <hr style="border-top: 2px solid #bfbfbf;margin-top: 24px;" />
              </div>
            </div>
            <div v-if="this.$route.params.type == 'edit'">
              <p style="color:#F3766A;">* Updation allowed till 11:00 PM</p>
            </div>
            <input
              v-if="isEditMin == false"
              class="btn btn-themeOrange inner"
              type="button"
              value="Add new item"
              @click="AddItem"
            />
          </div>

          <div class="row" style="float:right;">
            <b-spinner
              v-if="loading"
              class="m-2 col-3"
              role="status"
              variant="primary"
            ></b-spinner>
            <button
              :disabled="loading || disableMin"
              class="col-3 btn btn-themeYellow w-md waves-effect waves-light"
              type="button"
              @click="isEdit ? updateForm() : submitForm()"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isEditMin == true || isEdit == true" class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive mb-0">
            <b-table
              :current-page="currentPage"
              :fields="fields"
              :filter="filter"
              :filter-included-fields="filterOn"
              :items="tableData"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              bordered
              hover
              outlined
              responsive
              striped
              @filtered="onFiltered"
            >
              <template v-slot:cell(date)="data">
                <router-link
                  :to="{
                    name: 'mealPlanStatisticsTable',
                    params: {
                      date: data.item.created_at,
                      corpBranchID: data.item.corpBranchID,
                      restBranchID: data.item.restBranchID,
                      type: 'filtered'
                    }
                  }"
                >
                  <span v-if="data.item.date">{{ data.item.date }} </span>
                </router-link>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <!-- Department Modal -->
    <b-modal
      id="departmentModal"
      ok-title="Save"
      size="lg"
      title="Departments"
      title-class="font-18"
    >
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th style="max-width: 37px;">
              <label class="form-checkbox">
                <input
                  v-model="selectAllDepartment"
                  class="form-check-input"
                  type="checkbox"
                  @click="select"
                />
                <i class="form-icon"></i>
                Select All
              </label>
            </th>
            <th>Department</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="j in departmentList" :key="j.departmentID">
            <td>
              <label class="form-checkbox">
                <input
                  v-model="selectedDepartment"
                  :value="j.departmentID"
                  class="form-check-input"
                  type="checkbox"
                />
                <i class="form-icon"></i>
              </label>
            </td>
            <td>{{ j.departmentName }}</td>
          </tr>
        </tbody>
      </table>
    </b-modal>
    <!-- Departmodal End -->

    <!-- employeeModal -->
    <b-modal
      id="employeeModal"
      ok-title="Save"
      size="lg"
      title="Employees"
      title-class="font-18"
    >
      <input
        id="searchInput"
        placeholder="Search for names.."
        title="Type in a name"
        type="text"
        @keyup="searchEmployeeByName()"
      />
      <table id="employeeListTable" class="table table-striped table-hover">
        <thead>
          <tr>
            <th>
              <label class="form-checkbox">
                <input
                  v-model="selectAllEmployees"
                  class="form-check-input"
                  type="checkbox"
                  @click="selectEmployees"
                />
                <i class="form-icon"></i>
                Select All
              </label>
            </th>
            <th>Name</th>
            <th>Email ID</th>
            <th>Department</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="k in employeeList" :key="k.userID">
            <td>
              <label class="form-checkbox">
                <input
                  v-model="selectedEmployees"
                  :value="k.userID"
                  class="form-check-input"
                  type="checkbox"
                />
                <i class="form-icon"></i>
              </label>
            </td>
            <td>{{ k.userName }}</td>
            <td>{{ k.emailID }}</td>
            <td>{{ k.departmentName }}</td>
          </tr>
        </tbody>
      </table>
    </b-modal>
    <!-- employeeModal end -->
  </Layout>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.22.2/moment.min.js"></script>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { authHeader } from "../../../helpers/fakebackend/auth-header";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import moment from "moment";

/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Add Meal Plan",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: {
    Multiselect,
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "Meal Plan",
      items: [
        {
          text: "Meal Plan",
          href: "/"
        },
        {
          text: "Add",
          active: true
        }
      ],

      status: "",
      submitted: false,
      showDismissibleAlert: false,
      loading: false,
      corporates: [],
      corpBranchID: "",
      cafeteriaArr: [],
      restBranch: "",
      applicableTo: "1",
      showSellingPrice: "1",
      enablePreOrder: "0",
      weeklyMenuVisibility: "0",
      preOrderCutOff: "",
      cancellationCutOff: "",
      bulkOrderAdmin: "",
      startDate: "",
      endDate: "",
      holidayList: [],
      holidayID: "",
      corpMealPlanID: 0,
      isEdit: false,
      isEditMin: false,
      mealPlanData: [],
      mealsArr: [
        {
          id: 1,
          mealTypeName: "",
          discountType: "",
          discountInRs: "",
          discountPercent: "",
          maxDiscountCapping: "",
          corpMealID: 0,
          nonVegCountInRs: "",
          vegCount: "",
          nonVegCount: "",
          vegCountInRs: "",
          start_time: "",
          endT_time: ""
        }
      ],
      discountTypeArr: [
        { disType: 0, disTypeName: "Free Meal" },
        { disType: 1, disTypeName: "Net Discount" },
        { disType: 2, disTypeName: "Percent Discount" }
      ],
      mealTypeName: "",
      discountInRs: "",
      discountPercent: "",
      discountType: "",
      maxDiscountCapping: 0,
      vegCount: "",
      nonVegCount: "",
      selectedDepartment: [],
      departmentList: [],
      selectAllDepartment: false,
      corporateAdminsList: [],
      selectedEmployees: [],
      employeeList: [],
      selectAllEmployees: false,
      excludeSun: false,
      excludeSat: false,

      fields: [
        {
          key: "reportDay",
          label: "Day",
          sortable: true
        },
        {
          key: "date",
          sortable: true
        },
        {
          key: "mealPlanName",
          label: "Type",
          sortable: true
        },
        {
          key: "minGuaranteeVeg",
          label: "Min Gaur V",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "actualCountVeg",
          label: "Act count V",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "varianceVeg",
          label: "Variance V",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "minGuaranteeNonVeg",
          label: "Min Gaur NV",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "actualCountNonVeg",
          label: "Act count NV",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "varianceNonVeg",
          label: "Variance NV",
          sortable: true,
          tdClass: "align-right"
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, "All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      tableData: [],
      dateFilter: [],
      timestamp: "",
      checkHourStart: "",
      currentHour: "",
      disableMin: false,
      nowDate: new Date(Date.now() + 3600 * 1000 * 24)
        .toISOString()
        .slice(0, 10)
    };
  },

  validations: {
    restBranch: {
      required
    },
    corpBranchID: {
      required
    },
    selectedDepartment: {
      required: function() {
        return this.applicableTo == 2 && this.selectedDepartment == ""
          ? false
          : true;
      }
    },
    selectedEmployees: {
      required: function() {
        return this.applicableTo == 3 && this.selectedEmployees == ""
          ? false
          : true;
      }
    }
  },
  methods: {
    submitForm() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.scrollTo(0, 0);
        return;
      } else {
        let formData = new FormData();

        formData.append("corpBranchID", this.corpBranchID.corpBranchID);
        formData.append("restBranchID", this.restBranch.restBranchID);
        formData.append(
          "departmentIDs",
          JSON.stringify(this.selectedDepartment)
        );
        formData.append("holidayID", JSON.stringify(this.holidayID));
        formData.append("userIDs", JSON.stringify(this.selectedEmployees));
        formData.append("applicableTo", this.applicableTo);
        formData.append("showSellingPrice", this.showSellingPrice);
        formData.append("enablePreOrder", this.enablePreOrder);
        formData.append("weeklyMenuVisibility", this.weeklyMenuVisibility);
        formData.append("preOrderCutOff", this.preOrderCutOff);
        formData.append("cancellationCutOff", this.cancellationCutOff);
        formData.append("startDate", this.startDate);
        formData.append("endDate", this.endDate);
        formData.append("excludeSun", this.excludeSun);
        formData.append("excludeSat", this.excludeSat);
        formData.append("mealPlanArr", JSON.stringify(this.mealsArr));
        formData.append("createdBy", this.$storageData.profile.pid);
        formData.append("loginTypeID", this.$storageData.login_type);
if(  this.bulkOrderAdmin){
  formData.append("bulkOrderAdmin", this.bulkOrderAdmin.corpAdminID);
}
        this.axios
          .post(this.$loggedRole+"/addCorporateMealPlan", formData, {
            headers: authHeader()
          })
          .then(result => {
            this.status = result.data.data;
            this.showDismissibleAlert = true;
            this.loading = false;

            setTimeout(() => {
              // this.$router.push({ name: "corporateMealPlanTable" });
              this.showDismissibleAlert = false;
              window.location.reload();
            }, 2000);

            // console.warn(result);
          })
          .catch(error => {
            this.loading = false;
            alert(error.response.data.data);
          });
      }
    },

    updateForm() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.scrollTo(0, 0);
        return;
      } else {
        let formData = new FormData();

        formData.append("corpMealPlanID", this.corpMealPlanID);
        formData.append("corpBranchID", this.corpBranchID.corpBranchID);
        formData.append("restBranchID", this.restBranch.restBranchID);
        formData.append(
          "departmentIDs",
          JSON.stringify(this.selectedDepartment)
        );
        formData.append("holidayID", JSON.stringify(this.holidayID));
        formData.append("userIDs", JSON.stringify(this.selectedEmployees));
        formData.append("applicableTo", this.applicableTo);
        formData.append("showSellingPrice", this.showSellingPrice);
        formData.append("enablePreOrder", this.enablePreOrder);
        formData.append("weeklyMenuVisibility", this.weeklyMenuVisibility);
        formData.append("preOrderCutOff", this.preOrderCutOff);
        formData.append("cancellationCutOff", this.cancellationCutOff);
        formData.append("excludeSun", this.excludeSun);
        formData.append("excludeSat", this.excludeSat);
        formData.append("startDate", this.startDate);
        formData.append("endDate", this.endDate);
        formData.append("mealPlanArr", JSON.stringify(this.mealsArr));
        formData.append("createdBy", this.$storageData.profile.pid);
        formData.append("loginTypeID", this.$storageData.login_type);
        formData.append("email", this.$storageData.email);
        if(this.bulkOrderAdmin){
          formData.append("bulkOrderAdmin", this.bulkOrderAdmin.corpAdminID);
        }

        formData.append("previousData", JSON.stringify(this.mealPlanData));

        if (this.isEditMin == true) {
          formData.append("edit", "isEditMin");
        } else {
          formData.append("edit", "isEdit");
        }

        this.axios
          .post(this.$loggedRole+"/updateCorporateMealPlan", formData, {
            headers: authHeader()
          })
          .then(result => {
            this.status = result.data.data;
            this.showDismissibleAlert = true;
            this.loading = false;

            setTimeout(() => {
              // this.$router.push({ name: "corporateMealPlanTable" });
              this.showDismissibleAlert = false;
              window.location.reload();
            }, 2000);

            // console.warn(result);
          })
          .catch(error => {
            this.loading = false;
            alert(error.response.data.message);
          });
      }
    },
    getCorporateList() {
      this.axios.get(this.$loggedRole+"/getCorporateBranchList").then(result => {
        this.corporates = result.data.data;
      });
    },
    changeCorporate() {
      this.getCorporateCafeteria(this.corpBranchID.corpBranchID); // passing corporate Branch ID
      this.getHolidayList(this.corpBranchID.corporateID); // passing corporate ID
      this.getCorporateAdmins();
    },
    getCorporateCafeteria(corpBranchID) {
      this.axios
        .post(this.$loggedRole+"/getCorporateCafeteria", { corpBranchID: corpBranchID })
        .then(result => {
          this.cafeteriaArr = result.data.data;
        });
    },
    getHolidayList(corporateID) {
      this.axios
        .post(this.$loggedRole+"/viewAllCorporateHolidays", { corporateID: corporateID })
        .then(result => {
          this.holidayList = result.data.data;
        });
    },
    getCorporateDepartmentList() {
      this.axios
        .post(this.$loggedRole+"/getCorporateDepartmentList", {
          corpBranchID: this.corpBranchID.corpBranchID
        })
        .then(response => {
          this.departmentList = response.data.data;
        });
    },
    getCorporateEmployeeList() {
      this.axios
        .post(this.$loggedRole+"/getCorporateEmployeeList", {
          corpBranchID: this.corpBranchID.corpBranchID
        })
        .then(response => {
          this.employeeList = response.data.data;
        });
    },

    getCorporateAdmins() {
      this.axios
        .get(this.$loggedRole+"/get-corporate-admins/" + this.corpBranchID.corpBranchID)
        .then(response => {
          this.corporateAdminsList = response.data.data;
        })
        .catch(error => {
          alert(error.response.data.message);
        });
    },

    openDepartmentModal() {
      if (this.corpBranchID.corpBranchID) {
        this.$root.$emit("bv::show::modal", "departmentModal");
        this.getCorporateDepartmentList();
      } else {
        alert("Please select corporate first");
      }
    },
    openEmployeeModal() {
      if (this.corpBranchID.corpBranchID) {
        this.$root.$emit("bv::show::modal", "employeeModal");
        this.getCorporateEmployeeList();
      } else {
        alert("Please select corporate first");
      }
    },
    searchEmployeeByName() {
      var input, filter, table, tr, td, i, txtValue;
      input = document.getElementById("searchInput");
      filter = input.value.toUpperCase();
      table = document.getElementById("employeeListTable");
      tr = table.getElementsByTagName("tr");
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[1];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }
      }
    },
    select() {
      this.selectedDepartment = [];
      // alert();
      if (!this.selectAllDepartment) {
        for (let i in this.departmentList) {
          this.selectedDepartment.push(this.departmentList[i].departmentID);
        }
      }
    },
    selectEmployees() {
      this.selectedEmployees = [];
      // alert();
      if (!this.selectAllEmployees) {
        for (let i in this.employeeList) {
          this.selectedEmployees.push(this.employeeList[i].userID);
        }
      }
    },

    AddItem() {
      this.mealsArr.push({
        corpMealID: 0,
        mealTypeName: "",
        discountType: "",
        discountInRs: "",
        discountPercent: "",
        maxDiscountCapping: "",
        start_time: "",
        end_time: ""
      });
      //console.log(this.mealsArr);
    },
    getCorporateMealPlan() {
      this.axios
        .post(this.$loggedRole+"/getCorporateMealPlanFormData", {
          corpMealPlanID: this.corpMealPlanID
        })
        .then(result => {
          this.mealPlanData = result.data.data;
          this.corpBranchID = {
            corpBranchID: this.mealPlanData.corpBranchID,
            corpBranchName: this.mealPlanData.corpBranchName
          };
          this.corpBranchID.corpBranchID = this.mealPlanData.corpBranchID;
          this.getCorporateCafeteria(this.corpBranchID.corpBranchID);
          this.getHolidayList(this.mealPlanData.corporateID);
          this.getCorporateAdmins();
          this.applicableTo = this.mealPlanData.applicableTo;
          this.showSellingPrice = this.mealPlanData.showSellingPrice;
          this.enablePreOrder = this.mealPlanData.enable_pre_order;
          this.weeklyMenuVisibility = this.mealPlanData.weekly_menu_visibility;
          this.preOrderCutOff = this.mealPlanData.pre_order_cut_off;
          this.cancellationCutOff = this.mealPlanData.cancellation_cut_off;
          this.bulkOrderAdmin = this.mealPlanData.bulkOrderAdmin;
          this.startDate = this.mealPlanData.startDate;
          this.endDate = this.mealPlanData.endDate;
          this.holidayID = result.data.holidayArr;
          this.restBranch = this.mealPlanData.restDetails;
          this.selectedDepartment = result.data.depData;
          this.selectedEmployees = result.data.selectedUsers;
          this.excludeSat = this.mealPlanData.excludeSat == 1 ? true : false;
          this.excludeSun = this.mealPlanData.excludeSun == 1 ? true : false;
          this.mealsArr =
            result.data.mealPlans != ""
              ? result.data.mealPlans
              : [
                  {
                    id: 1,
                    mealTypeName: "",
                    discountType: "",
                    discountInRs: "",
                    discountPercent: "",
                    maxDiscountCapping: "",
                    corpMealID: 0,
                    nonVegCountInRs: "",
                    vegCount: "",
                    nonVegCount: "",
                    vegCountInRs: "",
                    start_time: "",
                    end_time: ""
                  }
                ];
        });
    },
    deleteItem(index, corpMealID) {
      if (corpMealID > 0) {
        if (confirm("Are you sure you want to delete this element?")) {
          this.mealsArr.splice(index, 1);
          this.axios
            .post(this.$loggedRole+"/deleteCorpMeal", { corpMealID: corpMealID })
            .then(() => {});
        }
      } else {
        this.mealsArr.splice(index, 1);
      }
    },

    readMealPlanReport() {
      this.axios
        .post(this.$loggedRole+"/getMealPlanFullReport", {
          corpBranchID: this.mealPlanData.corpBranchID,
          principleID: 0,
          principleBranchID: 0,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          transactionType: 0,
          dateFilter: this.dateFilter,
          restBranchID: this.restBranch.restBranchID,
          corpMealPlanID: this.corpMealPlanID
        })
        .then(response => {
          //Then injecting the result to datatable parameters.

          this.tableData = response.data.cardData.datatableData;
        });
    },
    getNow: function() {
      this.timestamp = moment().format("HH:mm"); //dateTime;
    }
  },
  created() {
    this.corpMealPlanID = this.$route.params.id;
    if (this.corpMealPlanID) {
      sessionStorage.setItem("corpMealPlanID", this.corpMealPlanID); // Save to sessionStorage
    }
    if (this.$route.params.type == "edit") {
      this.isEdit = true;
    } else {
      this.isEdit = false;
    }

    if (this.$route.params.type == "editMin") {
      this.isEdit = true;
      this.isEditMin = true;
    } else {
      // this.isEdit = false;
      this.isEditMin = false;
    }
  },
  mounted() {
    this.getNow();
    var checkHourStart = moment("23:00", "HH:mm");
    var currentHour = moment(this.timestamp, "HH:mm");

    if (currentHour.isAfter(checkHourStart)) {
      this.disableMin = true;
    }

    this.getCorporateList();
    if (this.isEdit == true && sessionStorage.getItem("corpMealPlanID") > 0) {
      this.corpMealPlanID = sessionStorage.getItem("corpMealPlanID");
      this.getCorporateMealPlan();
      this.isEdit = true;
      this.readMealPlanReport();
    }
  },

  middleware: "authentication"
};
</script>
<style>
.alertMsg {
  position: fixed;
  right: 0px;
  z-index: 500;
  top: 79px;
  width: 22%;
}

#searchInput {
  background-image: url("../../../assets/images/searchicon.png");
  background-position: 10px 10px;
  background-repeat: no-repeat;
  width: 100%;
  font-size: 16px;
  padding: 12px 20px 12px 40px;
  border: 1px solid #ddd;
  margin-bottom: 12px;
}

#employeeListTable {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
  font-size: 14px;
}

#employeeListTable th,
#employeeListTable td {
  text-align: left;
  padding: 12px;
}

#employeeListTable tr {
  border-bottom: 1px solid #ddd;
}

#employeeListTable tr.header,
#employeeListTable tr:hover {
  background-color: #f1f1f1;
}
</style>
